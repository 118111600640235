import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { Link, withPrefix } from 'gatsby';
import Helmet from "react-helmet"
import RichText from '../components/richText';



const Facturacion = (props) => {

  const showCrediton =  false;
  const assets = 'assets'



  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };


  const $facturacion = props.data.prismic.allFacturacion_electronicas.edges[0].node;


  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-facturacion'}>


        <SEO metadata={$metadata} title={'Facturación electrónica'}></SEO>


        <>

          <Helmet>

          <link href={withPrefix(`../../${assets}/css/fixed/icons.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=1`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=2`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=4`)} rel="stylesheet" type="text/css" />            

          </Helmet>



          <div className="o-wrapper o-section o-section--large">
            <h2 className={`c-title c-title--center c-title--blue ${showCrediton && 'c-title--red'}`}>Facturación Electrónica</h2>
            <div className="s-content o-wrapper o-wrapper--xs">

              <RichText render={$facturacion.contenido} />

            </div>
          </div>


        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query FacturacionQuery {
  prismic {
    allMetadatas  {
      edges {
        node {
          ...MetadataFragment
        }
      }
    },
    allFooters {
      edges {
        node {
          ...FooterFragment
        }
      }
    },
    allFacturacion_electronicas {
      edges {
        node {
          contenido
        }
      }
    },
    allInformacion_financieras {
      edges {
        node {
          ...InformacionFinancieraFragment
        }
      }
    }

  }
}
`


export default Facturacion;

